import fetch from 'isomorphic-unfetch';
import { detect } from 'detect-browser';
import { v4 as uuidv4 } from 'uuid';

const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const getDeviceData = async () => {
  let ret = {};

  try {
    const resp = await fetch(
      'https://api.ipapi.com/check?access_key=90a02200baf869c01c08b949f322c19f',
    );

    if (resp) {
      ret = await resp.json();
    }
  } catch (error) {
    // do nothing
  }

  return ret;
};

export default {
  getDocSigningEmbed: async (config, orgId, applicant) => {
    // if we've already established an eSign package for this applicant then just fetch the signing link
    const linkOnly = !!applicant.eSign;
    const dataResponse = await transientRequest(`${config.apiPath}createDocPackage`, {
      orgId,
      applicantId: applicant.id,
      linkOnly,
    });

    return dataResponse.link;
  },
  getJobs2: async (config, orgId, source, locationId, brandQueryString, int) => {
    const ret = {
      jobs: [],
      states: [],
      cities: [],
      showFilters: false,
      hasJobs: false,
    };

    let dataResponse = await transientRequest(`${config.apiPath}getJobPortalJobs`, {
      data: {
        orgId,
        source,
        locationId,
        brandQueryString,
        internal: int,
      },
    });

    dataResponse = dataResponse?.result;

    // check to make sure any state or city passed in is available, otherwise don't use it
    if (dataResponse) {
      ret.jobs = dataResponse.jobs || [];
      ret.states = dataResponse.states || [];
      ret.cities = dataResponse.cities || [];
    }

    ret.showFilters = dataResponse?.showFilters;
    ret.hasJobs = dataResponse?.hasJobs;

    if (dataResponse?.city || dataResponse?.state) {
      ret.showFilters = true;
      ret.city = dataResponse.city;
      ret.state = dataResponse.state;
    }

    return ret;
  },

  getFranchiseJobs: async (config, franchisorId, int) => {
    let jobs = [];

    const props = [
      'title',
      'type',
      'orgId',
      'locationId',
      'location.street',
      'location.city',
      'location.state',
      'location.zipCode',
      'location.coords',
      'location.brand',
      'internalOnly',
    ];

    let response = await fetch(
      `${config.newApiPath}/pub/franchise/${franchisorId}/jobs?int=${int}&p=${props.join(',')}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    );
    response = await response.json();

    if (response.success === true) {
      jobs = response.data;
    }

    // jobs.forEach((p) => {
    //   p.brand = mapBrandResponse(p.org, p, p.location?.brand);
    // });

    return jobs;
  },

  getJob: async (config, orgId, jobId) => {
    let job = {};

    let response = await fetch(`${config.newApiPath}/pub/orgs/${orgId}/jobs/${jobId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    response = await response.json();

    if (response.success === true) {
      job = response.data;
    }

    return job;
  },

  /**
   * Gets all applications made under the same email address. Also brings back additional information
   * about the organization.
   */
  getAllApplications: async (config, orgId, applicantId) => {
    const response = await fetch(
      `${config.newApiPath}/pub/orgs/${orgId}/allApplications/${applicantId}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    );

    const result = await response.json();

    return result;
  },
  /**
   * Gets data for a single application.
   */
  getApplication: async (config, orgId, applicantId) => {
    const response = await fetch(
      `${config.newApiPath}/pub/orgs/${orgId}/getApplication/${applicantId}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    );

    const result = await response.json();

    return result;
  },

  getOffer: async (config, orgId, applicantId) => {
    let offerDetails = {};

    let response = await fetch(`${config.newApiPath}/pub/orgs/${orgId}/offer/${applicantId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    response = await response.json();

    if (response.success === true) {
      offerDetails = response.data;
    }

    return offerDetails;
  },

  getOnboardingStatus: async (config, orgId, onboardingId) => {
    let ret;

    let response = await fetch(`${config.newApiPath}/pub/onboarding/${orgId}/${onboardingId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    response = await response.json();

    if (response.success === true) {
      ret = response.data;
    }

    return ret;
  },

  updateOfferStatus: async (config, orgId, applicantId, status) => {
    let updateResult = await fetch(
      `${config.newApiPath}/pub/orgs/${orgId}/offerstatus/${applicantId}`,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ offerStatus: status, onboardingStatusChangeDt: Date.now() }),
      },
    );
    updateResult = await updateResult.json();
    return updateResult;
  },
  sendCode: async (config, orgId, applicantId, type) => {
    await fetch(
      `${config.newApiPath}/pub/orgs/${orgId}/sendCode/${applicantId}?type=${type || 'sms'}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    );
  },
  verifyCode: async (config, orgId, applicantId, authCode) => {
    let verifyResult = await fetch(
      `${config.newApiPath}/pub/orgs/${orgId}/verifyCode/${applicantId}/${authCode}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    );
    verifyResult = await verifyResult.json();
    console.log(verifyResult);

    return verifyResult.success === true ? verifyResult.data : { isValid: false, authGuid: null };
  },
  getEsignUrl: async (config, orgId, applicantId) => {
    let result = await fetch(`${config.newApiPath}/pub/orgs/${orgId}/esign/${applicantId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    result = await result.json();
    console.log(result);
    return result.success === true && result.data && result.data.eSignUrl
      ? result.data.eSignUrl
      : null;
  },

  triggerDocubeeWorkflow: async (config, docubeeToken, docubeeWorkflowId, applicantId, orgId) => {
    let result = await fetch(`${config.newApiPath}/docubee/triggerWorkflow`, {
      method: 'POST',
      body: JSON.stringify({ docubeeToken, docubeeWorkflowId, applicantId, orgId }),
      headers: { 'Content-Type': 'application/json' },
    });
    result = await result.json();

    return result;
  },
  getDocubeeEmbedLink: async (
    config,
    docubeeToken,
    docubeeWorkflowInstanceId,
    participantEmail,
    domainUrl,
  ) => {
    let result = await fetch(`${config.newApiPath}/docubee/getEmbedLink`, {
      method: 'POST',
      body: JSON.stringify({
        docubeeToken,
        docubeeWorkflowInstanceId,
        participantEmail,
        domainUrl,
      }),
      headers: { 'Content-Type': 'application/json' },
    });
    result = await result.json();

    return result;
  },

  getDeviceData,
  transientRequest,
  getJobPortalDetails: async (config, requestData) => {
    return transientRequest(`${config.apiPath}getJobPortalDetails`, requestData).then((resp) => {
      return resp;
    });
  },
  getJobPortalJob: async (config, requestData) => {
    return transientRequest(`${config.apiPath}getJobPortalJob`, requestData).then((resp) => {
      return resp;
    });
  },
  getFollowUpV2: async (config, requestData) => {
    return transientRequest(`${config.apiPath}getFollowup_v2`, requestData).then((resp) => {
      return resp;
    });
  },
  getReferral: async (config, requestData) => {
    return transientRequest(`${config.apiPath}getReferral`, requestData).then((resp) => {
      return resp;
    });
  },

  logJobView: async (props) => {
    const ret = {
      browser: null,
      jobViewId: null,
      userAgent: null,
    };

    try {
      const utcDt = new Date();
      const dt = utcDt.getTime();

      utcDt.setHours(0, 0, 0, 0);
      const day = utcDt.getTime();

      const deviceType = null;
      const device = null;

      const browser = detect();

      // setup base jobView object
      let jobView = {
        id: props.appId || uuidv4(),
        orgId: props.org.id,
        jobId: props.job ? props.job.id : props.jobId,
        viewDt: dt,
        viewDay: day,
        browserName: browser.name,
        browserVersion: browser.version,
        browserOs: browser.os,
        deviceType,
        device,
        userAgent: navigator.userAgent || null,
      };

      const userData = await getDeviceData();

      jobView = { ...jobView, ...userData };

      jobView.source = props.source || '';
      jobView.campaign = props.campaign || '';
      jobView.tid = props.tid || null;
      jobView.etd = props.etd || null;
      jobView.fbclid = props.fbclid || null;
      jobView.gclid = props.gclid || null;
      jobView.referer = props.referer || null;
      jobView.portalVer = props.portalVer || null;
      jobView.referrerId = props.referrerId || null;

      if (jobView) {
        await fetch(`${props.config.apiPath}updateJobView`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ data: { jobView } }),
        });
      }

      ret.browser = browser;
      ret.userAgent = jobView.userAgent;
      ret.jobViewId = jobView.id;
    } catch (error) {
      console.log(error);
    }

    return ret;
  },

  /**
   * Opts out a given group of applications under the same e-mail address from the hiring process.
   */
  hiringProcessOptOut: async (config, orgId, applicantId, applications, isCRMOptout) => {
    const response = await fetch(
      `${config.newApiPath}/pub/orgs/${orgId}/hiringProcessOptOut/${applicantId}`,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ applications, isCRMOptout }),
      },
    );

    const result = await response.json();

    return result;
  },
};

/**
 * @description Executes a cloud function endpoint with the requestData provided
 * @param {string} endpoint - the endpoint name
 * @param {object} requestData - request data passed to the endpoint
 * @returns {object} response - the endpoint response
 */
async function transientRequest(endpointUri, requestData) {
  let response = null;

  const maxRetries = 5;
  let retries = 0;
  let success = false;

  do {
    // console.log(`Running attempts ${retries} of ${maxRetries}`);
    try {
      response = await fetch(endpointUri, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData),
      });

      response = await response.json();

      // const rand = Math.floor(Math.random() * 10) + 1;

      // if (retries < 4)
      //     throw 'FAKE EXCEPTION ################## !!!!!!!!!!!!!!!! ***************';

      success = true;
    } catch (error) {
      retries++;
      const sleepTime = 200 * retries;
      // logger.logException(error, null, { endpoint, retries, ...requestData });
      await timeout(sleepTime);
    }
  } while (!success && retries < maxRetries);

  // const totalTimeMs = Date.now() - startDt;
  // logger.log('TransRequest', { totalTimeMs, endpoint, retries });

  return response;
}
